import Axios from 'axios';
import { CredentialsType } from '../Pages/Login/Login';
import { generalErrorHandler } from './ErrorHandler';

type LoginUser = {
  id: number;
  email: string;
  token: string;
};

export const loginUser = async ({ email, password }: CredentialsType) => {
  try {
    const {
      data,
    } = await Axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/authentication`,
      { email, password }
    );
    return data as LoginUser;
  } catch (error) {
    throw generalErrorHandler(error);
  }
};
