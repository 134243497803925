import { Box } from '@material-ui/core';
import Sidebar from '../../../Components/Sidebar/Sidebar';

const Dashboard = () => {
  return (
    <Sidebar title='Dashboard'>
      <Box>Hello There</Box>
    </Sidebar>
  );
};

export default Dashboard;
