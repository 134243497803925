import DOMPurify from 'dompurify';
import ApplicationBar from '../../../Components/ApplicationBar/ApplicationBar';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ContentType, getContent } from '../../../Model/Content';
import { Box, Container, Divider, Typography } from '@material-ui/core';

const ContentDetail = () => {
  const param: { id: string | undefined } = useParams();
  const id = param.id;

  const [content, setContent] = useState<null | ContentType>(null);

  useQuery(
    ['/detail', id],
    () => {
      if (id === undefined) return null;
      return getContent(id);
    },
    {
      onSuccess: (data) => {
        if (data === null) {
          toast.dark('No Content');
        }
        setContent(data);
      },
      onError: (err) => {
        toast.dark(err as String);
      },
    }
  );

  return (
    <ApplicationBar>
      <Container>
        <Box mt={3}>
          <Typography variant='h5'>{content?.title}</Typography>
          <Divider />
          {content !== null ? (
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(content.body, {
                  USE_PROFILES: { html: true },
                }),
              }}
            />
          ) : (
            ''
          )}
        </Box>
      </Container>
    </ApplicationBar>
  );
};

export default ContentDetail;
