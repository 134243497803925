import Login from './Pages/Login/Login';
import NotFound from './Pages/NotFound/NotFound';
import Authentication from './Utility/Authentication';
import Home from './Pages/User/Home/Home';
import Dashboard from './Pages/Admin/Dashboard/Dashboard';
import Content from './Pages/Admin/Content/Content';
import AddContent from './Pages/Admin/Content/ContentComponents/AddContent';
import Search from './Pages/User/Search/Search';
import EditContent from './Pages/Admin/Content/ContentComponents/EditContent';
import ContentDetail from './Pages/User/ContentDetail/ContentDetail';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { theme } from './Components/Style/Style';
import { ProtectedRoute } from './Utility/ProtectedRoute';
import { QueryClient, QueryClientProvider } from 'react-query';
import Thesaurus from './Pages/User/Thesaurus/Thesaurus';

interface PublicRouteInterface {
  id: number;
  path: string;
  component: () => JSX.Element;
}

const PublicRoute: PublicRouteInterface[] = [
  {
    id: 1,
    path: '/',
    component: Home,
  },
  {
    id: 2,
    path: '/admin',
    component: Login,
  },
  {
    id: 200,
    path: '/content/search/:keyword',
    component: Search,
  },
  {
    id: 201,
    path: '/content/detail/:id',
    component: ContentDetail,
  },
  {
    id: 300,
    path: '/thesaurus',
    component: Thesaurus,
  },
  {
    id: 999,
    path: '*',
    component: NotFound,
  },
];

const ProtectedRouteData: PublicRouteInterface[] = [
  {
    id: 101,
    path: '/dashboard',
    component: Dashboard,
  },
  {
    id: 110,
    path: '/content',
    component: Content,
  },
  {
    id: 111,
    path: '/content/add',
    component: AddContent,
  },
  {
    id: 112,
    path: '/content/edit/:id',
    component: EditContent,
  },
];

const queryClient = new QueryClient();

function App() {
  return (
    <Router>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Authentication>
            <Switch>
              {ProtectedRouteData.map((data, index) => {
                return (
                  <ProtectedRoute
                    key={index}
                    exact
                    path={data.path}
                    component={data.component}
                  ></ProtectedRoute>
                );
              })}
              {PublicRoute.map((data, index) => {
                return (
                  <Route
                    key={index}
                    exact
                    path={data.path}
                    component={data.component}
                  ></Route>
                );
              })}
            </Switch>
          </Authentication>
        </ThemeProvider>
      </QueryClientProvider>
    </Router>
  );
}

export default App;
