import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Slide from '@material-ui/core/Slide';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router';

interface Props {
  window?: () => Window;
  children: React.ReactElement;
}

function HideOnScroll(props: Props) {
  const { children, window } = props;

  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction='down' in={!trigger}>
      {children}
    </Slide>
  );
}

export default function ApplicationBar(props: Props) {
  const history = useHistory();

  return (
    <React.Fragment>
      <CssBaseline />
      <HideOnScroll {...props}>
        <AppBar>
          <Container>
            <Toolbar
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <Typography variant='h6'>Kamus Hukum Adat</Typography>
              <Box display='flex'>
                <Box mr={2}>
                  <Button
                    onClick={() => history.push('/')}
                    style={{ color: 'white' }}
                  >
                    Beranda
                  </Button>
                </Box>
                <Box mr={2}>
                  <Button
                    onClick={() => history.push('/thesaurus')}
                    style={{ color: 'white' }}
                  >
                    Kamus
                  </Button>
                </Box>
                {/* <Box>
                  <Button
                    onClick={() => history.push('/')}
                    style={{ color: 'white' }}
                  >
                    Tentang
                  </Button>
                </Box> */}
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
      </HideOnScroll>
      <Toolbar />
      <Box>{props.children}</Box>
    </React.Fragment>
  );
}
