import Sidebar from '../../../Components/Sidebar/Sidebar';
import DeleteContent from './ContentComponents/DeleteContent';
import { Box, Button, IconButton, Paper, Tooltip } from '@material-ui/core';
import { useContext, useState } from 'react';
import { useQuery } from 'react-query';
import { AuthContext } from '../../../Utility/Authentication';
import { toast } from 'react-toastify';
import { DataGrid, GridColDef, GridToolbar } from '@material-ui/data-grid';
import { Add, Edit } from '@material-ui/icons';
import { theme } from '../../../Components/Style/Style';
import { useHistory } from 'react-router';
import {
  ContentType,
  deleteContent,
  getAllContents,
} from '../../../Model/Content';

const Content = () => {
  const authentication = useContext(AuthContext);
  const history = useHistory();

  const [contentData, setContentData] = useState<ContentType[]>([]);
  const [deletedId, setDeletedId] = useState(0);

  const { refetch } = useQuery<ContentType[]>(
    '/content',
    () => getAllContents(authentication.auth.data.token),
    {
      onSuccess: (data) => {
        setContentData(data);
      },
      onError: (error) => {
        toast.dark(error as string);
      },
      retry: false,
    }
  );

  const handleDelete = async () => {
    try {
      await deleteContent(authentication.auth.data.token, deletedId);
      toast.dark('Deleted content');
      refetch();
    } catch (error) {
      toast.dark(error as string);
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'No',
      headerName: 'No',
      flex: 1,
      valueGetter: (params) =>
        params.rowIndex !== undefined ? params.rowIndex + 1 : 0,
      renderCell: (params) => {
        return <>{params.rowIndex !== undefined ? params.rowIndex + 1 : 0}</>;
      },
    },
    { field: 'title', headerName: 'Title', flex: 1 },
    { field: 'summary', headerName: 'Summary', flex: 1 },
    {
      field: 'author',
      headerName: 'Author',
      flex: 1,
      valueGetter: ({ row }) => row['author'].email,
      renderCell: ({ row }) => {
        const { email } = row['author'];
        return <>{email}</>;
      },
    },
    {
      field: 'id',
      headerName: 'Action',
      flex: 1,
      renderCell: ({ row }) => {
        const idContent: any = row['id'];
        return (
          <>
            <Box onClick={() => setDeletedId(idContent)}>
              <DeleteContent handleDelete={handleDelete} />
            </Box>

            <Box ml={1}>
              <Tooltip title='Edit'>
                <IconButton
                  onClick={() => history.push(`/content/edit/${idContent}`)}
                  size='small'
                >
                  <Edit style={{ color: theme.palette.warning.main }} />
                </IconButton>
              </Tooltip>
            </Box>
          </>
        );
      },
    },
  ];

  return (
    <Sidebar title='Content'>
      <Paper elevation={0}>
        <Box px={3} pt={3} mb={1}>
          <Button
            onClick={() => history.push('/content/add')}
            startIcon={<Add />}
            color='primary'
            variant='contained'
          >
            Add
          </Button>
        </Box>
        <Box px={3} pb={3} height='76vh'>
          <DataGrid
            rows={contentData}
            columns={columns}
            autoPageSize
            components={{ Toolbar: GridToolbar }}
            disableSelectionOnClick
          />
        </Box>
      </Paper>
    </Sidebar>
  );
};

export default Content;
