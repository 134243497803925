// Component to redirect user if not yet log in
// This component inherits everything from Route from react-router-dom

import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthContext } from './Authentication';

export const ProtectedRoute = ({ component: Component, ...rest }: any) => {
  const authentication = useContext(AuthContext);
  return (
    <Route
      {...rest}
      render={(props) => {
        if (authentication.auth.isAuth === true) {
          return <Component {...props} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: '/',
                state: {
                  from: props.location,
                },
              }}
            />
          );
        }
      }}
    />
  );
};
