import { AxiosError } from 'axios';

export const generalErrorHandler = (error: AxiosError): string => {
  if (error.response) {
    const { data, status } = error.response;
    if (status === 403) {
      return 'Your token is expired, please re-login';
    }
    if (data) {
      if (data.message) {
        return data.message;
      } else {
        return error.response.statusText;
      }
    } else {
      return error.response.statusText;
    }
  } else if (error.request) {
    return 'Connection lost';
  } else {
    return 'Something went wrong';
  }
};
