import { Grid, Link, Typography } from '@material-ui/core';
import { ContentType } from '../../Model/Content';

const ContentCard = (content: ContentType) => {
  return (
    <Grid item xs={12} md={6}>
      <Link href={`/content/detail/${content.id}`}>
        <Typography variant='h6'>{content.title}</Typography>
      </Link>
      <Typography variant='body1'>{content.summary}</Typography>
    </Grid>
  );
};

export default ContentCard;
