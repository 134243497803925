import ApplicationBar from '../../../Components/ApplicationBar/ApplicationBar';
import { Box, Container, Divider, Grid, Link } from '@material-ui/core';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { ContentType, getContentStartWith } from '../../../Model/Content';
import { useHistory } from 'react-router';

function range(start: string, stop: string) {
  let result = [];
  for (
    let idx = start.charCodeAt(0), end = stop.charCodeAt(0);
    idx <= end;
    ++idx
  ) {
    result.push(String.fromCharCode(idx));
  }
  return result;
}

const arrayAlphabet = range('A', 'Z');

const Thesaurus = () => {
  const history = useHistory();

  const [char, setChar] = useState('A');
  const [listContent, setListContent] = useState<ContentType[]>([]);

  useQuery(
    [`/content/search/start/${char}`, char],
    () => getContentStartWith(char),
    {
      onSuccess: (data) => {
        setListContent(data);
      },
      onError: (err) => {
        toast.dark(err as string);
      },
      retry: false,
    }
  );

  return (
    <ApplicationBar>
      <Container>
        <Box
          display='flex'
          alignItems='center'
          justifyContent='space-between'
          mt={4}
          mb={2}
        >
          {arrayAlphabet.map((element) => (
            <Link
              key={element}
              href='#'
              onClick={(e: any) => {
                e.preventDefault();
                setChar(element);
              }}
            >
              {element}
            </Link>
          ))}
        </Box>
        <Divider />
        <Box mt={2}>
          <Grid container spacing={2}>
            {listContent.map((element) => (
              <Grid item xs={12} md={3}>
                <Link
                  key={element.id}
                  href='#'
                  onClick={(e: any) => {
                    e.preventDefault();
                    history.push(`/content/detail/${element.id}`);
                  }}
                >
                  {element.title}
                </Link>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </ApplicationBar>
  );
};

export default Thesaurus;
