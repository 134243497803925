import { Box, Button, Paper, TextField, Typography } from '@material-ui/core';
import { useContext, useState } from 'react';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { loginUser } from '../../Model/Authentication';
import { AuthContext } from '../../Utility/Authentication';

export type CredentialsType = {
  email: string;
  password: string;
};

const Login = () => {
  const history = useHistory();
  const authentication = useContext(AuthContext);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [credentials, setCredentials] = useState<CredentialsType>({
    email: '',
    password: '',
  });

  const submitCredentials = async () => {
    setIsSubmitting(true);
    try {
      const result = await loginUser(credentials);
      authentication.func.changeAuthToTrue(result);
      history.push('/dashboard');
    } catch (error) {
      toast.dark(error);
      setIsSubmitting(false);
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      submitCredentials();
    }
  };

  return (
    <Box
      width='100vw'
      height='100vh'
      display='flex'
      alignItems='center'
      justifyContent='center'
    >
      <Paper elevation={3}>
        <Box p={3} width='20vw'>
          <Box textAlign='center'>
            <Typography variant='h5'>Login</Typography>
          </Box>
          <Box>
            <TextField
              fullWidth
              label='Email'
              value={credentials.email}
              onChange={(e) => {
                setCredentials((c) => ({ ...c, email: e.target.value }));
              }}
              onKeyPress={handleKeyPress}
            />
          </Box>
          <Box mt={2}>
            <TextField
              fullWidth
              label='Password'
              type='password'
              value={credentials.password}
              onChange={(e) => {
                setCredentials((c) => ({ ...c, password: e.target.value }));
              }}
              onKeyPress={handleKeyPress}
            />
          </Box>
          <Box mt={4}>
            <Button
              disabled={isSubmitting}
              variant='contained'
              color='primary'
              fullWidth
              onClick={submitCredentials}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default Login;
