import Sidebar from '../../../../Components/Sidebar/Sidebar';
import BackToolbar from '../../../../Components/BackToolbar/BackToolbar';
import ReactQuill from 'react-quill';
import { Box, Button, Paper, TextField } from '@material-ui/core';
import { useContext, useState } from 'react';
import { postContent } from '../../../../Model/Content';
import { AuthContext } from '../../../../Utility/Authentication';
import { toast } from 'react-toastify';

const AddContent = () => {
  const authentication = useContext(AuthContext);

  const [text, setText] = useState('');
  const [summary, setSummary] = useState('');
  const [title, setTitle] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (content: string) => {
    setText(content);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      await postContent(authentication.auth.data.token, {
        title,
        summary,
        body: text,
        authorId: authentication.auth.data.id,
      });
      toast.success('Success');
      setText('');
      setTitle('');
      setSummary('');
    } catch (error) {
      toast.dark(error);
    }
    setIsSubmitting(false);
  };

  return (
    <Sidebar title='Add Content'>
      <Paper elevation={0}>
        <Box px={3} pt={3} pb={1}>
          <BackToolbar />
        </Box>
        <Box px={3} pb={3} height='70vh' width='100%'>
          <Box mb={1}>
            <TextField
              label='Title'
              variant='outlined'
              size='small'
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Box>
          <Box mb={1}>
            <TextField
              multiline
              rows={3}
              label='Summary'
              variant='outlined'
              size='small'
              value={summary}
              onChange={(e) => setSummary(e.target.value)}
            />
          </Box>
          <ReactQuill theme='snow' value={text} onChange={handleChange} />
          <Box mt={1}>
            <Button
              disabled={isSubmitting}
              onClick={handleSubmit}
              variant='contained'
              color='primary'
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Paper>
    </Sidebar>
  );
};

export default AddContent;
