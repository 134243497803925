import { createMuiTheme } from '@material-ui/core';

export const theme = createMuiTheme({
  typography: {
    fontFamily: ['PT Sans'].join(','),
    button: {
      textTransform: 'none',
    },
  },
  spacing: 8,
  palette: {
    primary: {
      main: '#1769aa',
    },
    secondary: {
      main: '#1D85D7',
    },
    background: {
      default: '#ffffff',
    },
  },
});
