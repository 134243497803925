import Sidebar from '../../../../Components/Sidebar/Sidebar';
import BackToolbar from '../../../../Components/BackToolbar/BackToolbar';
import ReactQuill from 'react-quill';
import { Box, Button, Paper, TextField } from '@material-ui/core';
import { useContext, useState } from 'react';
import { getContent, putContent } from '../../../../Model/Content';
import { AuthContext } from '../../../../Utility/Authentication';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

const EditContent = () => {
  const authentication = useContext(AuthContext);
  const param: { id: string | undefined } = useParams();
  const id = param.id;

  const [text, setText] = useState('');
  const [summary, setSummary] = useState('');
  const [title, setTitle] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  useQuery(
    '/content/detail',
    () => {
      if (id === undefined) return null;
      return getContent(id);
    },
    {
      onSuccess: (data) => {
        if (data === null) return;
        setTitle(data.title);
        setSummary(data.summary);
        setText(data.body);
      },
      onError: (err) => {
        toast.dark(err as string);
      },
      retry: false,
    }
  );

  const handleChange = (content: string) => {
    setText(content);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      if (id === undefined) return;
      await putContent(
        authentication.auth.data.token,
        {
          title,
          summary,
          body: text,
        },
        id
      );
      toast.success('Success');
    } catch (error) {
      toast.dark(error);
    }
    setIsSubmitting(false);
  };

  return (
    <Sidebar title='Add Content'>
      <Paper elevation={0}>
        <Box px={3} pt={3} pb={1}>
          <BackToolbar />
        </Box>
        <Box px={3} pb={3} height='70vh' width='100%'>
          <Box mb={1}>
            <TextField
              label='Title'
              variant='outlined'
              size='small'
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Box>
          <Box mb={1}>
            <TextField
              multiline
              rows={3}
              label='Summary'
              variant='outlined'
              size='small'
              value={summary}
              onChange={(e) => setSummary(e.target.value)}
            />
          </Box>
          <ReactQuill theme='snow' value={text} onChange={handleChange} />
          <Box mt={1}>
            <Button
              disabled={isSubmitting}
              onClick={handleSubmit}
              variant='contained'
              color='primary'
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Paper>
    </Sidebar>
  );
};

export default EditContent;
