import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Tooltip, IconButton } from '@material-ui/core';
import { Delete } from '@material-ui/icons';

type DeleteContentProps = {
  handleDelete: () => void;
};

export default function DeleteContent({ handleDelete }: DeleteContentProps) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Tooltip title='Delete'>
        <IconButton size='small' onClick={handleClickOpen}>
          <Delete style={{ color: 'red' }} />
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          {'Yakin akan menghapus'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Data akan dihapus dan tidak bisa dikembalikan.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            Tidak
          </Button>
          <Button
            onClick={() => {
              handleDelete();
              handleClose();
            }}
            color='primary'
            autoFocus
          >
            Ya
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
