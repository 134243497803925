// Component to store global state to provide necessary data for children

import React, { createContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  clearCredential,
  getCredential,
  saveCredential,
} from '../Session/Session';

interface Data {
  id: number;
  email: string;
  token: string;
}
export interface UserData {
  isAuth: boolean;
  data: Data;
}

interface UserFunction {
  changeAuthToFalse: () => void;
  changeAuthToTrue: (data: Data) => void;
}

interface AuthenticationState {
  auth: UserData;
  func: UserFunction;
}

interface AuthenticationProps {
  children: React.ReactNode;
}

const defaultAuth: AuthenticationState = {
  auth: {
    isAuth: false,
    data: {
      id: -1,
      email: '',
      token: '',
    },
  },
  func: {
    changeAuthToFalse: () => {},
    changeAuthToTrue: () => {},
  },
};

export const AuthContext = createContext(defaultAuth);

const Authentication = ({ children }: AuthenticationProps) => {
  const history = useHistory();

  let defaultAuth: AuthenticationState = {
    auth: {
      isAuth: false,
      data: {
        id: -1,
        email: '',
        token: '',
      },
    },
    func: {
      changeAuthToFalse: () => {},
      changeAuthToTrue: () => {},
    },
  };
  defaultAuth.auth = getCredential();
  const [auth, setAuth] = useState(defaultAuth);

  const changeAuthToFalse = () => {
    clearCredential();
    setAuth({
      auth: {
        isAuth: false,
        data: {
          id: -1,
          email: '',
          token: '',
        },
      },
      func: {
        changeAuthToFalse: () => {},
        changeAuthToTrue: () => {},
      },
    });
    history.push('/');
  };

  const changeAuthToTrue = (data: Data) => {
    let newAuth = defaultAuth;
    newAuth.auth.isAuth = true;
    newAuth.auth.data = data;
    saveCredential(newAuth.auth);
    setAuth(newAuth);
  };

  return (
    <AuthContext.Provider
      value={{
        auth: auth.auth,
        func: {
          changeAuthToFalse: changeAuthToFalse,
          changeAuthToTrue: changeAuthToTrue,
        },
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default Authentication;
