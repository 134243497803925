import ApplicationBar from '../../../Components/ApplicationBar/ApplicationBar';
import { useHistory } from 'react-router';
import { useState } from 'react';
import { toast } from 'react-toastify';
import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';

const Home = () => {
  const history = useHistory();

  const [keyword, setKeyword] = useState('');

  const searchContent = () => {
    if (keyword === '') {
      toast.dark('Fill the keyword');
      return;
    }
    history.push(`/content/search/${keyword}`);
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      searchContent();
    }
  };

  return (
    <ApplicationBar>
      <Container>
        <Grid
          container
          style={{
            justifyContent: 'center',
            textAlign: 'center',
            marginTop: '16px',
          }}
        >
          <Grid item xs={12} md={6}>
            <Typography variant='h5'>Cari Kata</Typography>
            <br />
            <TextField
              variant='outlined'
              size='small'
              fullWidth
              placeholder='Cari disini...'
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              onKeyPress={handleKeyPress}
            />
            <Box mt={2}>
              <Button
                onClick={searchContent}
                fullWidth
                color='secondary'
                variant='contained'
              >
                Cari
              </Button>
            </Box>

            {/* <Box mt={2} textAlign='left'>
              <Typography>Saran Pencarian</Typography>
              <Link href=''>Cat, </Link>
              <Link href=''>Dog</Link>
            </Box> */}
          </Grid>
        </Grid>
      </Container>
    </ApplicationBar>
  );
};

export default Home;
