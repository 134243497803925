import SearchIcon from '@material-ui/icons/Search';
import ApplicationBar from '../../../Components/ApplicationBar/ApplicationBar';
import { useState } from 'react';
import { useParams } from 'react-router';
import { useQuery } from 'react-query';
import { ContentType, getContentByKeyword } from '../../../Model/Content';
import { toast } from 'react-toastify';
import { Box, Button, Container, Grid, TextField } from '@material-ui/core';
import ContentCard from '../../../Components/ContentCard/ContentCard';

const Search = () => {
  const param: { keyword: string | undefined } = useParams();
  const keywordParam = param.keyword;

  const [keyword, setKeyword] = useState(keywordParam ? keywordParam : '');
  const [enable, setEnable] = useState(true);
  const [listContent, setListContent] = useState<ContentType[]>([]);

  const searchContent = () => {
    if (keyword === '') {
      toast.dark('Fill the search bar');
      return;
    }
    setEnable(true);
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      searchContent();
    }
  };

  useQuery(['/search', keyword], () => getContentByKeyword(keyword), {
    onSettled: () => {
      setEnable(false);
    },
    onSuccess: (data) => {
      setListContent(data);
    },
    onError: (err) => {
      toast.dark(err as String);
    },
    enabled: enable,
    retry: false,
  });

  return (
    <ApplicationBar>
      <Box mt={3}>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} md={5}>
              <TextField
                variant='outlined'
                size='small'
                fullWidth
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
                onKeyPress={handleKeyPress}
              />
            </Grid>
            <Grid item xs={12} md={1}>
              <Button
                onClick={searchContent}
                variant='contained'
                color='secondary'
                startIcon={<SearchIcon />}
              >
                Cari
              </Button>
            </Grid>
          </Grid>
          <Box mt={3}>
            {listContent.map((element) => (
              <Grid key={element.id} container spacing={2}>
                <ContentCard {...element} />
              </Grid>
            ))}
          </Box>
        </Container>
      </Box>
    </ApplicationBar>
  );
};

export default Search;
