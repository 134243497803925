import { UserData } from '../Utility/Authentication';

const LOCAL_AUTH_KEY = 'AUTH';

export function saveCredential(authentication: UserData): void {
  localStorage.setItem(LOCAL_AUTH_KEY, JSON.stringify(authentication));
}

export function getCredential(): UserData {
  const authJSON = localStorage.getItem(LOCAL_AUTH_KEY);
  if (!authJSON)
    return {
      isAuth: false,
      data: {
        id: -1,
        email: '',
        token: '',
      },
    };
  let auth = JSON.parse(authJSON);
  return auth;
}

export function clearCredential(): void {
  localStorage.removeItem(LOCAL_AUTH_KEY);
}
