import Axios, { AxiosRequestConfig } from 'axios';
import { generalErrorHandler } from './ErrorHandler';

export interface Author {
  email: string;
}

export interface ContentType {
  id: number;
  title: string;
  summary: string;
  body: string;
  authorId: number;
  author: Author;
}

export const getAllContents = async (token: string) => {
  try {
    const config: AxiosRequestConfig = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const { data } = await Axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/content`,
      config
    );
    return data as ContentType[];
  } catch (error) {
    throw generalErrorHandler(error);
  }
};

export const getContent = async (id: string) => {
  try {
    const { data } = await Axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/content/${id}`
    );
    return data as ContentType;
  } catch (error) {
    throw generalErrorHandler(error);
  }
};

type PostContentMeta = {
  title: string;
  summary: string;
  body: string;
  authorId: number;
};

export const postContent = async (token: string, meta: PostContentMeta) => {
  try {
    const config: AxiosRequestConfig = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const { data } = await Axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/content`,
      meta,
      config
    );
    return data;
  } catch (error) {
    throw generalErrorHandler(error);
  }
};

type PutContentMeta = {
  title: string;
  summary: string;
  body: string;
};

export const putContent = async (
  token: string,
  meta: PutContentMeta,
  id: string
) => {
  try {
    const config: AxiosRequestConfig = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const { data } = await Axios.put(
      `${process.env.REACT_APP_BACKEND_URL}/content/${id}`,
      meta,
      config
    );
    return data;
  } catch (error) {
    throw generalErrorHandler(error);
  }
};

export const getContentByKeyword = async (keyword: string) => {
  try {
    const { data } = await Axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/content/search/${keyword}`
    );
    return data as ContentType[];
  } catch (error) {
    throw generalErrorHandler(error);
  }
};

export const deleteContent = async (token: string, id: number) => {
  try {
    const config: AxiosRequestConfig = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const { data } = await Axios.delete(
      `${process.env.REACT_APP_BACKEND_URL}/content/${id}`,
      config
    );
    return data;
  } catch (error) {
    throw generalErrorHandler(error);
  }
};

export const getContentStartWith = async (startWith: string) => {
  try {
    const { data } = await Axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/content/search/start/${startWith}`
    );
    return data as ContentType[];
  } catch (error) {
    throw generalErrorHandler(error);
  }
};
