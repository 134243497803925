import { Box, Button, Divider } from '@material-ui/core';
import { ArrowLeft } from '@material-ui/icons';
import { useHistory } from 'react-router';

const BackToolbar = () => {
  const history = useHistory();
  return (
    <Box>
      <Button onClick={() => history.goBack()} startIcon={<ArrowLeft />}>
        Back
      </Button>
      <Divider />
    </Box>
  );
};

export default BackToolbar;
